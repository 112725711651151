/*jshint esversion: 6 */
import Ajax from '../common/Ajax';
import * as Utils from '../common/Utils';

export default (function (config) {
    const pageNo = 1;
    let startAjaxTime;
    let nextImageIntervalTimer;
    const images = [];

    let preloadHTML = function (images) {
        let html = '';
        let i = 0;

        for (i; i < images.length; i++) {
            html += buildHTMLItem(images[i], i, (i === 0));
        }
        return html;
    };

    let buildHTMLItem = function (image, page, display) {
        const style = (display) ? 'display: block;' : 'display: none;';
        const nextPage = ((page + 1) >= images.length) ?  0 : page + 1;
        const previousPage = (page === 0) ? images.length - 1 : page - 1;

        let linkTarget = '_self';
        if (image.variant) {
            let parts = Utils.analyseVariant(image.variant);
            if (parts[0] === 'REDIRECT' && parts[2]) {
                linkTarget = parts[2];
            }
        }

        let linkHref = `/${config.locale}/video/${image.slug}`;

        const html = `
            <div style="${style}" class="carousel-item">
                <div class="item-thumb">
                    <a href="${linkHref}" target="${linkTarget}">
                        <img width="526" height="296"  src="${image.images[config.preferredImageSize]}" alt="${image.title}">
                    </a>
                </div>
                <div class="arrow-bar sg-gradient-grey btn-list-featured">
                    <a href="#" class="arrow arrow-previous active page-featured-1 icon-chevron-left" aria-label="Previous" data-page="${previousPage}">
                        <div class="arrow-left visually-hidden">Previous</div>
                    </a>
                    <a href="#" class="arrow arrow-next active page-featured-2 icon-chevron-right" aria-label="Next" data-page="${nextPage}">
                        <div class="arrow-right visually-hidden">Next</div>
                    </a>
                </div>
                <div class="item-title lc-4">
                    <a href="${linkHref}" target="${linkTarget}">${image.title}</a>
                </div>
            </div>
        `;

        return html;
    };

    let generateRoute = function (limit) {
        return Utils.generateRouteFromPattern({
            pattern: {
                '%pageNo%': pageNo,
                '%limit%': limit
            },
            route: config.route,
            env: config.env,
            locale: config.locale
        });
    };

    let handleBatch = function (response) {
        if(response.length != 0) {
            for (let i = 0; i < response.length; i++) {
                images.push(response[i]);
            }
            let containers = document.getElementsByClassName('widget-contents-video_featured');
            if (containers.length > 0) {
                let container = document.getElementsByClassName('widget-contents-video_featured')[0];
                container.innerHTML = preloadHTML(images);
                attachLinks();
            }

            // Assuming no pagination
            // If ajax call is slower than the interval then the first image has displayed too long.
            // Display the next page if it exists immediately
            const difference = (Date.now() - startAjaxTime) - config.displayInterval;
            if (difference >= 0) {
                changePage(1);
            }
        } else {
            console.log('Video carousel AJAX returned 0 results in response');
        }
    };

    let errorCallback = function (error) {
        console.log(error);
    };

    let getImages = function (limit = config.maxImages) {
        limit = (limit > config.maxImages) ? config.maxImages : limit;
        limit = (limit < 1) ? 1 : limit;

        const route = generateRoute(limit);
        startAjaxTime = Date.now();
        Ajax.getRequest(route, handleBatch, errorCallback);
    };

    let attachLinks = function () {
        const links = document.getElementsByClassName('arrow');
        let i = 0;

        for(i; i < links.length; i++) {
            const link = links[i];
            link.onclick = function(e) {
                e.preventDefault();
                const page = parseInt(link.getAttribute('data-page'));
                changePage(page);
                return false;
            };
        }
    };

    let runInterval = function () {
        let update = function () {
            let items = document.getElementsByClassName('carousel-item');
            let i = 0;

            for (i; i < items.length; i++) {

                let item = items[i];
                const nextItem = i + 1;

                if (item.style.display === 'block') {
                    item.style.display = 'none';

                    if (nextItem < items.length) {
                        items[nextItem].style.display = 'block';
                    } else {
                        items[0].style.display = 'block';
                    }
                    break;
                }
            }
            return false;
        };

        nextImageIntervalTimer = setInterval(update, config.displayInterval);
    };

    let changePage = function (page) {
        let items = document.getElementsByClassName('carousel-item');
        let i = 0;

        for (i; i < items.length; i++) {
            let item = items[i];
            item.style.display = 'none';
            if(i === page) {
                item.style.display = 'block';
            }
        }

        // reset timer for changing image
        clearInterval(nextImageIntervalTimer);
        runInterval();
    };

    let refreshImages = function () {
        images.length = 0;
        getImages();
        clearInterval(nextImageIntervalTimer);
        runInterval();
    };

    getImages();
    runInterval();

    setInterval(refreshImages, config.dataRefreshInterval);
});