/*jshint esversion: 6 */

export default (function(config){


    function getUrlParameter(name, str) {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
        var results = regex.exec(str);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    };

    // Deal with pagination
    // var pagerEl = document.getElementsByClassName('pager');
    // if(pagerEl.length > 0) {
    //    var pager = pagerEl[0];
    //
    //     var pageLinks = pager.getElementsByTagName('a');
    //     for (var i = 0; i < pageLinks.length; i++) {
    //
    //         pageLinks[i].addEventListener('click', function(event) {
    //             event.preventDefault();
    //             // Set the page
    //             document.getElementsByClassName('filter_current_page')[0].value = getUrlParameter('page', this.getAttribute('href'));
    //             // Submit the form
    //             document.getElementById('search-form-container').submit();
    //         });
    //     }
    //
    // }

});


