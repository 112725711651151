/*jshint esversion: 6 */

export default (function(config){

    // Deal with tabs
    var tabs = document.getElementsByClassName('filter-generic-section');
    for (var i = 0; i < tabs.length; i++) {


        tabs[i].addEventListener('click', function(event) {
            event.preventDefault();
            // Set the section
            document.getElementById('home_section').value = this.getAttribute('data-section');
            // Submit the form
            document.forms['home'].submit();
        });
    }

});


