/*jshint esversion: 6 */

import GenericSearch from '../forms/search/GenericSearch.js';
import CategorySearch from '../forms/search/CategorySearch.js';
import FilterSearch from '../forms/search/FilterSearch.js';
import WidgetVideoCarouselPreload from "../widgets/WidgetVideoCarouselPreload.js";

WidgetVideoCarouselPreload({
    route: '/widget/carousel/video/featured/page/%pageNo%/%limit%',
    locale: LOCALE,
    env: ENV,
    type: 'video_featured',
    maxImages: IMAGE_CAROUSEL_MAX_IMAGES,
    displayInterval: IMAGE_CAROUSEL_DISPLAY_INTERVAL,
    dataRefreshInterval: IMAGE_CAROUSEL_DATA_REFRESH_INTERVAL,
    preferredImageSize: 'large'
});

GenericSearch();
FilterSearch();
CategorySearch({
    locale: LOCALE,
    env: ENV,
});